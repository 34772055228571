<template>
  <v-row>
    <v-col cols="12">
      <v-toolbar color="transparent mt-1" flat height="48">
        <v-toolbar-title class="ml-n3 subtitle-1">
          Folios
          <v-icon class="mx-1" color="secondary" size="22">mdi-chevron-right</v-icon>
          <router-link :to="{ name: 'FoliosMonitor' }" class="text-decoration-none secondary--text font-weight-bold">Monitor</router-link>
          <v-icon class="mx-1" color="secondary" size="22">mdi-chevron-right</v-icon>
          Reporte tiendas
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-row align="center">
            <!-- by status filter -->
            <span class="mr-2 body-2 font-weight-medium">Filtrar por estado</span>
            <v-btn-toggle color="primary">
              <v-btn>Crítico (3)</v-btn>
              <v-btn>Alerta (2)</v-btn>
              <v-btn>Normal (24)</v-btn>
            </v-btn-toggle>
            <!-- end by status filter -->

            <v-query-builder class="ml-4" :query-map="filters" model="folios" />
          </v-row>
        </v-toolbar-items>
      </v-toolbar>
    </v-col>

    <v-col cols="12">
      <v-data-table :headers="headers" :items="reportBranchList" hide-default-header disable-sort mobile-breakpoint hide-default-footer @click:row="(item) => $router.push({ name: 'FoliosRetrieve', params: { id: item.document_type } }).catch(() => {})" style="cursor:pointer">
        <template v-slot:header="{ props: { headers } }">
          <VTableHeaders :headers="headers" />
        </template>

        <template v-slot:[`item.id`]="{item}">
          <code @click="toClipboard(d.id)" class="pa-1 font-weight-regular text--secondary background">{{item.id}}</code>
        </template>

        <template v-slot:[`item.internal_id`]="{item}">
          <code @click="toClipboard(item.internal_id)" class="pa-1 font-weight-regular text--secondary background">{{item.internal_id}}</code>
        </template>

        <template v-slot:[`item.name`]="{item}">
          <span>{{item.name}} - {{item.address}}</span>
        </template>

        <template v-slot:[`item.status_bar`]="{item}">
          <v-progress-linear v-if="item.status >= 75" v-model="item.status" dark color="red lighten-1" height="8" rounded />
          <v-progress-linear v-else-if="item.status >= 50" v-model="item.status" dark color="orange lighten-1" height="8" rounded />
          <v-progress-linear v-else-if="item.status <= 25" v-model="item.status" dark color="green lighten-1" height="8" rounded />
        </template>

        <template v-slot:[`item.status`]="{item}">
          <v-chip v-if="item.status >= 75" color="red lighten-1" dark small>{{item.status}}% - Crítico</v-chip>
          <v-chip v-else-if="item.status >= 50" color="orange lighten-1" dark small>{{item.status}}% - Alerta</v-chip>
          <v-chip v-else-if="item.status <= 25" color="green lighten-1" dark small>{{item.status}}% - Normal</v-chip>
        </template>
      </v-data-table>
      <VMainPagination :count="10" />
    </v-col>
  </v-row>
</template>
<script>
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import documentTypesList from '@/collections/documentTypes'
import VMainPagination from '@/components/commons/VMainPagination'

export default {
  components: {
    VMainPagination,
    VQueryBuilder: () => import('@/components/VQueryBuilder/VQueryBuilder'),
    VTableHeaders: () => import('@/components/VTable/VTableHeaders')
  },
  mixins: [
    GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    count: 0,
    loading: false,
    documentTypes: documentTypesList.filter(d => d.country === 'CL'),
    headers: [
      { text: 'ID', value: 'id', width: 120 },
      { text: 'ID Interno', value: 'internal_id', width: 120 },
      { text: 'Tienda', value: 'name', sortable: true },
      { text: 'Riesgo operacional', value: 'status_bar', width: 220, sortable: true },
      { text: '', value: 'status', width: 100 }
    ],
    reportBranchList: [
      {
        id: 'bra_8pQ7xyL2Wmk1',
        status: 95,
        name: 'Tienda 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'tienda_54',
        document_type: "CL39"
      },
      {
        id: 'bra_8pQ7xyL2W9k3',
        status: 80,
        name: 'Tienda 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'tienda_54',
        document_type: "CL41"
      },
      {
        id: 'bra_8pQ7xyL2Wmk3',
        status: 78,
        name: 'Tienda 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'tienda_54',
        document_type: "CL33"
      },
      {
        id: 'bra_8pQ7xyL2Wmk7',
        status: 73,
        name: 'Tienda 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'tienda_54',
        document_type: "CL34"
      },
      {
        id: 'bra_8pQ7xyL2Wmk5',
        status: 52,
        name: 'Tienda 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'tienda_54',
        document_type: "CL52"
      },
      {
        id: 'bra_8pQ7xyL2Wmk4',
        status: 24,
        name: 'Tienda 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'tienda_54',
        document_type: "CL41"
      },
      {
        id: 'bra_8pQ7xyL1Wmk2',
        status: 24,
        name: 'Tienda 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'tienda_54',
        document_type: "CL39"
      },
      {
        id: 'bra_8pQ7xpL2Wmk6',
        status: 19,
        name: 'Tienda 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'tienda_54',
        document_type: "CL52"
      },
      {
        id: 'bra_8pQ9xyL2Wmk4',
        status: 14,
        name: 'Tienda 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'tienda_54',
        document_type: "CL39"
      },
      {
        id: 'bra_8pQ7xyL2Wmk2',
        status: 8,
        name: 'Tienda 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'tienda_54',
        document_type: "CL39"
      },
      {
        id: 'bra_8pQ7xyL2Wmk6',
        status: 4,
        name: 'Tienda 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'tienda_54',
        document_type: "CL52"
      }
    ],
    reportPosList: [
      {
        id: 'pos_b8KeaZcVq9wVpX6CU2',
        status: 17,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_4EwNoJGfsBTjos8BsE',
        status: 5,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_xhbMTDwcdcjM7a1S5M',
        status: 100,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      },
      {
        id: 'pos_AxlEPRxHrVWtF3yJZI',
        status: 48,
        name: 'Pos 45',
        address: 'M. Montt 501, Coronel',
        internal_id: 'pos_45_tienda_54'
      }
    ],
    statusReport: [
       {
        color: 'var(--axt-green-light)',
        range: [0, 25]
      },
      {
        color: 'var(--axt-yellow)',
        range: [26, 75]
      },
      {
        color: 'var(--axt-red-darken)',
        range: [76, 100]
      }
    ],
    filters: [
      {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        value: null,
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'id',
        label: 'ID',
        value: null,
        operators: ['contiene']
      },
      {
        type: 'date',
        id: 'created',
        label: 'Fecha de creación',
        value: null,
        operator: null,
        operators: ['es igual', 'es menor a', 'es mayor a']
      }
    ]
  }),
  computed: {
    emptyState () {
      return {
        title: 'Aún no tienes reportes',
        icon: 'mdi-chart-box'
      }
    },
    currentHeaderList () {
      this.headers.forEach(h => {
        if (h.value === 'detail') h.text = this.$route.query?.type === 'pos' ? 'POS' : 'Tienda'
       })
      return this.headers
    },
    currentList () {
      return this.$route.query?.type === 'pos' ? this.reportPosList : this.reportBranchList
    }
  },
  created () {
    setTimeout(() => {
      this.loading = false
    }, 1000)
  }
}
</script>